import { useContext } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import parse from 'html-react-parser';

const ManageMeCfs: React.FC = () => {
  const { managing_me_cfs: lang } = useContext(LanguageContext);
  return (
    <div className="carousel-item-container">
      <h1>{lang.title}</h1>
      <p>{parse(lang.sections[0].text[0])}</p>
      <ul>
        <li>{parse(lang.sections[0].text[1])}</li>
        <li>{parse(lang.sections[0].text[2])}</li>
        <li>{parse(lang.sections[0].text[3])}</li>
        <li>{parse(lang.sections[0].text[4])}</li>
      </ul>
      <hr />
      <h3 style={{ color: '#000000' }}>{parse(lang.sections[1].text[0])}</h3>
      <h3>{parse(lang.sections[1].text[1])}</h3>
      <ol>
        <li>{parse(lang.sections[1].text[2])}</li>
        <li>{parse(lang.sections[1].text[3])}</li>
        <li>
          {parse(lang.sections[1].text[4])}
          <ul>
            <li>{parse(lang.sections[1].text[5])}</li>
            <li>{parse(lang.sections[1].text[6])}</li>
            <li>{parse(lang.sections[1].text[7])}</li>
            <li>{parse(lang.sections[1].text[8])}</li>
            <li>{parse(lang.sections[1].text[9])}</li>
            <li>{parse(lang.sections[1].text[10])}</li>
          </ul>
        </li>
        <li>{parse(lang.sections[1].text[11])}</li>
        <li>
          {parse(lang.sections[1].text[12])}
          <ul>
            <li>{parse(lang.sections[1].text[13])}</li>
            <li>{parse(lang.sections[1].text[14])}</li>
            <li>{parse(lang.sections[1].text[15])}</li>
          </ul>
        </li>
      </ol>
      <hr />
      <p>{parse(lang.sections[2].text[0])}</p>
      <p>{parse(lang.sections[2].text[1])}</p>
      <p>{parse(lang.sections[2].text[2])}</p>
      <ul>
        <li>{parse(lang.sections[2].text[3])}</li>
        <li>{parse(lang.sections[2].text[4])}</li>
        <li>{parse(lang.sections[2].text[5])}</li>
        <li>{parse(lang.sections[2].text[6])}</li>
        <li>
          {parse(lang.sections[2].text[7])}
          <ul>
            <li>{parse(lang.sections[2].text[8])}</li>
          </ul>
        </li>
        <li>{parse(lang.sections[2].text[9])}</li>
        <li>{parse(lang.sections[2].text[10])}</li>
        <li>{parse(lang.sections[2].text[11])}</li>
      </ul>
      <div style={{height:'50px'}}>
      </div>
    </div>
  );
};

export default ManageMeCfs;
