import parse from "html-react-parser";
import { useContext } from "react";
import { LanguageContext } from "../../contexts/language-context";

const Stress: React.FC = () => {
  const {wearable_education: lang} = useContext(LanguageContext);
  return (
    <div className="carousel-item-container">
      <h4>{lang.sections[2].title}</h4>
      {
        lang.sections[2].text.map((t:string, ind:number) => (<p key={ind}>{parse(t)}</p>))
      }
    </div>
  );
};

export default Stress;
