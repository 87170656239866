import MyDataHelps from "@careevolution/mydatahelps-js";
import TaskService from "../services/task-service";
import customFields from "../config/custom-fields.json";
import surveyNames from "../config/survey-names.json";

export default async function loader() {
  const participant = await MyDataHelps.getParticipantInfo();
  if (participant.customFields[customFields.EnrollmentState] !== "Consented") {
    throw new Error("Not allowed to take baseline assessments.");
  }

  const taskNames = [
    surveyNames.BASELINE_WEEKLY_SURVEY,
    surveyNames.BASELINE_SYMPTOM_SURVEY,
    surveyNames.BASELINE_QUALITY_OF_LIFE_SURVEY,
    surveyNames.BASELINE_DIAGNOSIS_AND_DEMOGRAPHICS_SURVEY,
  ];

  return await TaskService.getIncompleteSurveyTasks(taskNames).then(
    (tasks) => {
      if (tasks.length > 0) {
        tasks[0].locked = false;
      }

      return tasks;
    }
  );
}
