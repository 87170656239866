import './study-resources.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LongCovidInfo from '../../components/long-covid-info';
import ManageMeCfs from '../../components/long-covid-info/manage-me-cfs';
import ManagePots from '../../components/long-covid-info/manage-pots';
import MoreInformation from '../../components/long-covid-info/more-information';
import WhatIsLongCOVID from '../../components/long-covid-info/what-is-long-covid';
import useCarousel from '../../hooks/use-carousel';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/language-context';

const LongCovid: React.FC = () => {
  const [props, _, setCurrentSlide] = useCarousel();
  const { long_covid: lang, download } = useContext(LanguageContext);

  return (
    <div className="study-resources">
      <Carousel {...props}>
        <div>
          <div className="cover carousel-item-container">
            <div
              style={{
                backgroundImage: `url('${process.env.PUBLIC_URL}/assets/General Long COVID Information.svg')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                height: '700px',
              }}
            >
              <h1>{lang.title}</h1>
              <a
                className="btn-primary"
                href={download.link}
                target="_blank"
                rel="noreferrer"
              >
                {download.text}
              </a>
            </div>
          </div>
        </div>
        <div>
          <div>
            <LongCovidInfo />
            <section>
              <div
                className="section-header"
                onClick={() => setCurrentSlide(2)}
              >
                <div>
                  <h5>{lang.sections[0]}</h5>
                </div>
                <div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </section>
            <section>
              <div
                className="section-header"
                onClick={() => setCurrentSlide(3)}
              >
                <div>
                  <h5>{lang.sections[1]}</h5>
                </div>
                <div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </section>
            <section>
              <div
                className="section-header"
                onClick={() => setCurrentSlide(4)}
              >
                <div>
                  <h5>{lang.sections[2]}</h5>
                </div>
                <div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </section>
            <section>
              <div
                className="section-header"
                onClick={() => setCurrentSlide(5)}
              >
                <div>
                  <h5>{lang.sections[3]}</h5>
                </div>
                <div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </section>
          </div>
        </div>
        <div>
          <WhatIsLongCOVID />
        </div>
        <div>
          <ManageMeCfs />
        </div>
        <div>
          <ManagePots />
        </div>
        <div>
          <MoreInformation />
        </div>
      </Carousel>
    </div>
  );
};

export default LongCovid;
