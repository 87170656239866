import { useContext } from "react";
import { LanguageContext } from "../../contexts/language-context";
import parse from "html-react-parser";

const BodyBattery: React.FC = () => {
  const {wearable_education: lang} = useContext(LanguageContext);
  return (
    <div className="carousel-item-container">
      <h4>{lang.sections[0].title}</h4>
      <p>
        {parse(lang.sections[0].text[0])}
      </p>
      <p>
        {parse(lang.sections[0].text[1])}
      </p>
      <p>
        {parse(lang.sections[0].text[2])}
      </p>
      <div
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/assets/BodyBattery.png"
          })`,
          width: "310px",
          height: "551px",
          margin: "1rem auto",
        }}
      ></div>
    </div>
  );
};

export default BodyBattery;
