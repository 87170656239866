import Loading from "../components/loading";
import MyDataHelps from "@careevolution/mydatahelps-js";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SurveyCard from "../components/survey-card";
import Task from "../types/Task";
import parse from "html-react-parser";
import { LanguageContext } from "../contexts/language-context";
import loader from "../loaders/quarterly-assessment-tasks-loader";

const QuarterlyAssessment: React.FC<{
  onTaskComplete: (taskName: string) => void;
}> = ({ onTaskComplete }) => {
  const {quarterly: lang} = useContext(LanguageContext);

  const [ loading, setLoading ] = useState<boolean>(true);
  const [ tasks, setTasks ] = useState<Task[]>([]);
  const [ complete, setComplete ] = useState<boolean>(false);

  const initialize = useCallback(async () => {
    setLoading(true);

    const baselineTasks = await loader();

    setTasks(baselineTasks);

    setComplete(baselineTasks.length === 0);

    setLoading(false);
  }, []); 

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onSurveyFinish = useCallback(
    (result: { reason: string; surveyName: string }) => {
      if (result.reason === "Completed") {
        onTaskComplete(
          tasks.find((t) => t.surveyName === result.surveyName)
            ?.displayName || result.surveyName
        );

        initialize();
      }
    },
    [onTaskComplete, tasks, initialize]
  );

  const renderCompletion = (complete: boolean) => {
    if (!complete) {
      return <></>;
    }

    setTimeout(() => MyDataHelps.back(), 3000);

    return <>
      <p>{lang.complete.title}</p>
      <p>{lang.complete.text}</p>
    </> 
  };

  useEffect(() => {
    MyDataHelps.on("surveyDidFinish", onSurveyFinish);

    return () => {
      MyDataHelps.off("surveyDidFinish", onSurveyFinish);
    };
  }, [onSurveyFinish]);

  return (
    <div>
      <div>
        <h1>{lang.title}</h1>
        <p>{parse(lang.text)}</p>
      </div>
      {loading && <Loading />}
      {!loading && 
        <div className="card-list">
          {tasks.map((t) => (
            <SurveyCard task={t} key={t.surveyName} />
          ))}
        </div>
      }
      {renderCompletion(complete)}
    </div>
  );
};

export default QuarterlyAssessment;
