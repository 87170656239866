import "./connect-garmin.css";
import GarminService from "../services/garmin-service";
import MyDataHelps from "@careevolution/mydatahelps-js";
import React, { useContext, useEffect } from "react";
import parse from "html-react-parser";
import { LanguageContext } from "../contexts/language-context";

const ConnectGarmin: React.FC = () => {
  const {connect_garmin: lang} = useContext(LanguageContext);

  function handleClick() {
    GarminService.connectGarmin().then(() => {
      MyDataHelps.completeStep("true");
    }).catch((error) => {
      console.log("Unable to connect to Garmin: " + error);
    });
  }

  useEffect(() => {
    // Advance to the next step if already connected.
    GarminService.isConnected().then((connected) => {
      if (connected) {
        MyDataHelps.completeStep("true");
      }
    });
  }, []);

  return (
    <div className="connect-garmin">
      <h1>{parse(lang.title)}</h1>
      <p>{parse(lang.text[0])}</p>
      <p>{parse(lang.text[1])}</p>
      <img
        src={process.env.PUBLIC_URL + "/assets/ConnectGarmin.svg"}
        style={{ margin: "0 auto", display: "block" }}
        alt="Connect To Garmin"
      />
      <div className="disclaimer center">
        {parse(lang.footer)}
      </div>
      <div className="next-button">
        <button onClick={handleClick}>
          {parse(lang.button_text)}
        </button>
      </div>
    </div>
  );
};

export default ConnectGarmin;
