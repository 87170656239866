import "./study-resources.css";
import { useContext, useState } from "react";
import { LanguageContext } from "../../contexts/language-context";
import MyDataHelps from "@careevolution/mydatahelps-js";

const StudyResources: React.FC = () => {
  const [summaryCollapsed, setSummaryCollapsed] = useState(true);
  const [fullCollapsed, setFullCollapsed] = useState(true);

  const { study_resources: lang, view, download } = useContext(LanguageContext);

  function toggleSummaryCollapsed() {
    setSummaryCollapsed(!summaryCollapsed);
  }

  function toggleFullCollapsed() {
    setFullCollapsed(!fullCollapsed);
  }

  return (
    <div className="study-resources">
      <div className="header">
        <h1>{lang.title}</h1>
        <p>{lang.text}</p>
      </div>
      <section className={summaryCollapsed ? "collapsed" : "expanded"}>
        <div className="section-header" onClick={toggleSummaryCollapsed}>
          <div>
            <label>{lang.sections[0].title}</label>
          </div>
          <div className={"plus-minus " + (summaryCollapsed ? "collapsed" : "expanded")}>
          </div>
        </div>
        <div className="expand-container">
          <div className={"section-body " + (summaryCollapsed ? "collapsed" : "expanded")}>
            <div className="item">
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/Book.svg"}
                  alt="Book"
                />
              </div>
              <div>
                <h4>{lang.sections[0].sections[0].title}</h4>
                <div>{lang.sections[0].sections[0].text}</div>
                <div className="button-row">
                  <button
                    className="btn-primary"
                    onClick={() => {
                      MyDataHelps.openApplication(window.location.origin + "/resources/long-covid-summary");
                    }}
                  >
                    {view}
                  </button>
                  <a
                    className="btn-secondary"
                    href={download.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {download.text}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={fullCollapsed ? "collapsed" : "expanded"}>
        <div className="section-header" onClick={toggleFullCollapsed}>
          <div>
            <label>{lang.sections[1].title}</label>
          </div>
          <div className={"plus-minus " + (fullCollapsed ? "collapsed" : "expanded")}>
          </div>
        </div>
        <div className="expand-container">
          <div className={"section-body " + (fullCollapsed ? "collapsed":"expanded")}>
            <div className="item">
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/Book.svg"}
                  alt="Book"
                />
              </div>
              <div>
                <h4>{lang.sections[1].sections[0].title}</h4>
                <div>{lang.sections[1].sections[0].text}</div>
                <div className="button-row">
                  <button
                    className="btn-primary"
                    onClick={() => {
                      MyDataHelps.openApplication(window.location.origin + "/resources/long-covid");
                    }}
                  >
                    {view}
                  </button>
                  <a
                    className="btn-secondary"
                    href={download.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {download.text}
                  </a>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/Book.svg"}
                  alt="Book"
                />
              </div>
              <div>
                <h4>{lang.sections[1].sections[1].title}</h4>
                <div>{lang.sections[1].sections[1].text}</div>
                <div className="button-row">
                  <button
                    className="btn-primary"
                    onClick={() => {
                      MyDataHelps.openApplication(
                        window.location.origin + 
                        "/resources/wearable-education"
                      );
                    }}
                  >
                    {view}
                  </button>
                  <a
                    className="btn-secondary"
                    href={download.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {download.text}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StudyResources;
