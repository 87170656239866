import { useContext } from "react";
import { LanguageContext } from "../../contexts/language-context";
import parse from "html-react-parser";

const LongCovidInfo: React.FC = () => {
  const { long_covid: lang } = useContext(LanguageContext);
  return (
    <div className="carousel-item-container">
      <h1>{lang.title}</h1>
      <p>
        {parse(lang.text)}
      </p>
    </div>
  );
};

export default LongCovidInfo;
