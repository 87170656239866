import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading: React.FC = () => {
  return (
    <div className="loading-indicator">
      <FontAwesomeIcon icon={faRefresh} spin />
    </div>
  );
};

export default Loading;
