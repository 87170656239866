import "./order-garmin.css";
import MyDataHelps from "@careevolution/mydatahelps-js";
import React, { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import { LanguageContext } from "../contexts/language-context";
import customFields from "../config/custom-fields.json";

const OrderGarmin: React.FC = () => {
  const [orderLinkClicked, setOrderLinkClicked] = useState(false);
  const { order_garmin: lang, step } = useContext(LanguageContext);
  const [code, setCode] = useState<string>("");

  function handleLinkClick() {
    setOrderLinkClicked(true);
  }

  function handleButtonClick() {
    MyDataHelps.completeStep("true");
  }

  useEffect(() => {
    MyDataHelps.getParticipantInfo().then((participant) => {
      const code = participant.customFields[customFields.VoucherCode];
      setCode(code);
    })

  }, []);

  if (code.length === 0) return (<></>);

  return (
    <div className="order-garmin">
      <h1>{lang.title}</h1>
      <p>{parse(lang.text[2])}</p>
      <hr />
      <label>
        <b>{step} 1</b>
      </label>
      <p>{parse(lang.text[0])}</p>
      <a
        href={lang.link.replace("%CODE%", code)}
        target="_blank"
        rel="noopener noreferrer"
        className="btn-primary"
        onClick={handleLinkClick}
      >
        {lang.order_button_text}
      </a>
      <img
        src={process.env.PUBLIC_URL + "/assets/OrderGarmin.png"}
        alt="Order Garmin"
      />
      <hr />
      <label>
        <b>{step} 2</b>
      </label>
      <p>{parse(lang.text[1])}</p>
      <div className="next-button">
        <button onClick={handleButtonClick} disabled={!orderLinkClicked}>
          {lang.next_button_text}
        </button>
      </div>
    </div>
  );
};

export default OrderGarmin;
