import MyDataHelps from "@careevolution/mydatahelps-js";
import React, { useContext } from "react";
import { LanguageContext } from "../contexts/language-context";
import Task from "../types/Task";

const SurveyCard: React.FC<{
  task: Task;
  clickUrl?: string;
  onClick?: () => void;
}> = ({ task, clickUrl, onClick }) => {

  const lang = useContext(LanguageContext);

  function handleClick() {
    if (task.locked) {
      return;
    }

    if (onClick) {
      onClick();
    }

    if (clickUrl) {
      MyDataHelps.openApplication(clickUrl);
      return;
    }

    MyDataHelps.startSurvey(task.surveyName);
  }

  function getClassName(task: Task) {
    const classes = ["card-item"];
    if (task.locked) {
      classes.push("card-item-locked");
    }

    return classes.join(" ");
  }

  return (
    <div className={getClassName(task)} onClick={handleClick}>
      <div className="card-body">
        <div className="card-text">
          <div className="card-title">{task.displayName}</div>
          {!task.locked && (
            <div className="card-body-description">
              <p>{task.description}</p>
            </div>
          )}
        </div>
        {task.locked && (
          <div className="card-body-icon lock-icon">
            <img
              src="https://rkstudio-customer-assets.s3.amazonaws.com/AllOfUs/dashboardcards/lock%403x.png"
              alt="locked"
            />
          </div>
        )}
      </div>
      <div className="card-footer">
        <span className="card-description">
          {task.hasSavedProgress && (
            <button className="card-action">{lang.continue.toUpperCase()}</button>
          )}
          {!task.hasSavedProgress && (
            <span className="time-to-complete">
              <img
                className="time-to-complete-icon"
                src="https://rkstudio-customer-assets.s3.amazonaws.com/AllOfUs/dashboardcards/clock.svg"
                alt="clock"
              />
              <span className="time-to-complete-text">
                {task.completionTime} min
              </span>
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default SurveyCard;
