import Loading from "../components/loading";
import MyDataHelps from "@careevolution/mydatahelps-js";
import ProgressBar from "../components/progress-bar";
import React, { useState } from "react";
import SurveyCard from "../components/survey-card";
import Task from "../types/Task";
import surveyNames from "../config/survey-names.json";
import { LanguageContext } from "../contexts/language-context";
import { useCallback, useContext, useEffect } from "react";
import parse from "html-react-parser";
import loader from "../loaders/enrollment-tasks-loader";

const Enroll: React.FC<{ onTaskComplete: (taskName: string) => void }> = ({
  onTaskComplete,
}) => {
  const [ loading, setLoading ] = useState<boolean>(true);
  const [ tasks, setTasks ] = useState<Task[]>([]);
  const [ progress, setProgress ] = useState<number>(0);
  const [ complete, setComplete ] = useState<boolean>(false);
  const [ ineligible, setIneligible ] = useState<boolean>(false);
  const [ expired, setExpired ] = useState<boolean>(false);
  const [ currentTask, setCurrentTask ] = useState<Task>();

  const { enrollment: lang } = useContext(LanguageContext);

  const initialize = useCallback(async () => {
    setLoading(true);

    const { enrollmentTasks, state } = await loader();

    setTasks(enrollmentTasks);

    switch (enrollmentTasks.length) {
      case 5:
        setProgress(0);
        break;
      case 4:
        setProgress(25);
        break;
      case 3:
        setProgress(50);
        break;
      case 2:
        setProgress(75);
        break;
      case 1:
      case 0:
        setProgress(100);
        break;
      default:
        throw new Error("Unexpected number of tasks");
    }

    setComplete(state === "DeviceOrdered");
    setIneligible(state === "Ineligible");
    setExpired(state === "Expired");
    setLoading(false);
  }, []); 

  const onSurveyFinish = useCallback(
    (result: { reason: string; surveyName: string }) => {
      if (
        result.surveyName === surveyNames.ELIGIBILITY ||
        result.surveyName === surveyNames.CONSENT ||
        result.surveyName === surveyNames.SETUP_GARMIN || 
        result.surveyName === surveyNames.ORDER_GARMIN 
      ) {

        if (result.reason === "Completed") {
          if (result.surveyName !== surveyNames.ORDER_GARMIN) {
            onTaskComplete(
              tasks.find((t) => t.surveyName === result.surveyName)
                ?.displayName || result.surveyName
            );
          }

          initialize();
        }
      }
    },
    [onTaskComplete, initialize, tasks]
  );

  useEffect(() => {
    initialize();
  }, [initialize])

  useEffect(() => {
    MyDataHelps.on("surveyDidFinish", onSurveyFinish);

    return () => {
      MyDataHelps.off("surveyDidFinish", onSurveyFinish);
    };
  }, [onSurveyFinish]);

  useEffect(() => {
    function reload() {
      if (currentTask?.surveyName === surveyNames.BASELINE_ASSESSMENT) {
        initialize();
      }
    }

    MyDataHelps.on("applicationDidBecomeVisible", reload);

    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", reload);
    };
  }, [initialize, currentTask]);

  function getClickUrl(task: Task) {
    if (task.surveyName === surveyNames.BASELINE_ASSESSMENT) {
      return window.location.origin + "/baseline";
    }

    return undefined;
  }

  function renderDescription(
    complete: boolean,
    expired: boolean,
    ineligible: boolean
  ) {
    if (complete) {
      setTimeout(() => MyDataHelps.back(), 3000);
      return <p>{lang.enrolled}</p>;
    }

    if (expired) {
      return (
        <>
          <h3 style={{ textAlign: "left" }}>{lang.contact_us.intro}</h3>
          <p>
            <b>lang.contact_us.title</b>
            <br />
            {parse(lang.contact_us.hours)}
            <br />
            <span>{parse(lang.contact_us.phone)}</span>
            <br />
            <span>{parse(lang.contact_us.email)}</span>
            <br />
            <span>{parse(lang.contact_us.chat)}</span>
          </p>
        </>
      );
    }

    if (ineligible) {
      setTimeout(() => MyDataHelps.back(), 3000);
      return <p>{lang.ineligible}</p>;
    }

    return <p>{lang.text}</p>;
  }

  return (
    <div className="enroll">
      <h1>{lang.title}</h1>
      {loading && <Loading />}
      {!loading &&
        <>
          {renderDescription(complete, expired, ineligible)}
          <ProgressBar value={progress} />
          <div className="card-list">
            {tasks.map((t) => (
              <SurveyCard
                task={t}
                key={t.surveyName}
                clickUrl={getClickUrl(t)}
                onClick={() => setCurrentTask(t)}
              />
            ))}
          </div>
        </>
      }
    </div>
  );
};

export default Enroll;
