import MyDataHelps from "@careevolution/mydatahelps-js";
import TaskService from "../services/task-service";
import customFields from "../config/custom-fields.json";
import surveyNames from "../config/survey-names.json";

export default async function loader() {
  const participant = await MyDataHelps.getParticipantInfo();
  const acceptStates = ["Active", "DeviceOrdered"];
  if (
    !acceptStates.includes(
      participant.customFields[customFields.EnrollmentState]
    )
  ) {
    throw new Error("Not allowed to take quarterly assessments.");
  }

  const taskNames = [
    surveyNames.QUARTERLY_SYMPTOM_SURVEY,
    surveyNames.QUARTERLY_QUALITY_OF_LIFE_SURVEY,
    surveyNames.QUARTERLY_STUDY_RESOURCES_SURVEY,
  ];

  return await TaskService.getIncompleteSurveyTasks(taskNames).then(
    (tasks) => {
      if (tasks.length > 0) {
        tasks[0].locked = false;
      }

      return tasks;
    }
  );
}
