import { toast, ToastContainer } from "react-toastify";
import "./toastify.css";
import parse from "html-react-parser";

const toastTaskCompletion = (message: string) => {
  toast.dismiss();
  toast.success(
    <div>
      {parse(message)}
    </div>,
    {
      icon: (
        <img alt="check" src={`${process.env.PUBLIC_URL}/assets/Check.png`} />
      ),
    }
  );
};

export { ToastContainer, toastTaskCompletion };
