import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useState } from "react";
import { CarouselProps } from "react-responsive-carousel";

const useCarousel: () => [
  Partial<CarouselProps>,
  number,
  (value: number) => void
] = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const arrowStyles: CSSProperties = {
    background: "#787678",
    border: "0.3px solid #C7C7CC",
    borderRadius: "50%",
    color: "#FFFFFF",
    cursor: "pointer",
    fontSize: 30,
    height: 60,
    mixBlendMode: "multiply",
    opacity: 0.8,
    paddingTop: 8,
    position: "fixed",
    textAlign: "center",
    top: "calc(50% - 30px)",
    width: 60,
    zIndex: 2,
  };

  const props = {
    showArrows: true,
    showStatus: false,
    showThumbs: false,
    autoPlay: false,
    dynamicHeight: true,
    infiniteLoop: false,
    preventMovementUntilSwipeScrollTolerance: true,
    swipeScrollTolerance: 50,
    selectedItem: currentSlide,
    renderArrowPrev: (
      onClickHandler: React.MouseEventHandler<HTMLDivElement>,
      hasPrev: boolean,
      label: string
    ) =>
      hasPrev && (
        <div
          onClick={onClickHandler}
          title={label}
          style={{ ...arrowStyles, left: 15 }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      ),
    renderArrowNext: (
      onClickHandler: React.MouseEventHandler<HTMLDivElement>,
      hasNext: boolean,
      label: string
    ) =>
      hasNext && (
        <div
          onClick={onClickHandler}
          title={label}
          style={{ ...arrowStyles, right: 15 }}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      ),
  };

  return [props, currentSlide, setCurrentSlide];
};

export default useCarousel;
