import { useContext } from "react";
import { LanguageContext } from "../../contexts/language-context";
import parse from "html-react-parser";

const WearableEducation: React.FC = () => {
  const {wearable_education: lang} = useContext(LanguageContext);
  return (
    <div className="carousel-item-container">
      <h1>{lang.title}</h1>
      <p>
        {parse(lang.text[0])}
        {" "}
        <a
          href={lang.links[0].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.links[0].text}
        </a>
        ,{" "}
        <a
          href={lang.links[1].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.links[1].text}
        </a>{" "}
        {lang.text[1]}{" "}
        <a
          href={lang.links[2].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.links[2].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[2])}
      </p>
      <p>
        {parse(lang.text[3])}
      </p>
    </div>
  );
};

export default WearableEducation;
