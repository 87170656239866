import "./index.css";
import App from "./app";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

if (
  window.location.hostname === "localhost" &&
  !!process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN
) {
  window.MyDataHelps.setParticipantAccessToken(
    {
      access_token: process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN,
      expires_in: 21600,
      token_type: "Bearer",
    },
    process.env.REACT_APP_BASE_URL
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
