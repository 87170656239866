import GarminService from "../services/garmin-service";
import MyDataHelps from "@careevolution/mydatahelps-js";
import TaskService from "../services/task-service";
import customFields from "../config/custom-fields.json";
import surveyNames from "../config/survey-names.json";

export default async function loader() {
  const participant = await MyDataHelps.getParticipantInfo();

  const state = participant.customFields[customFields.EnrollmentState];
  const code = participant.customFields[customFields.VoucherCode];

  const taskNames = [
    surveyNames.ELIGIBILITY,
    surveyNames.CONSENT,
    surveyNames.BASELINE_ASSESSMENT,
    surveyNames.SETUP_GARMIN,
    surveyNames.ORDER_GARMIN,
  ];

  const taskPromise = TaskService.getIncompleteSurveyTasks(taskNames);

  const baselineTaskPromise = TaskService.getIncompleteSurveyTasks([
    surveyNames.BASELINE_WEEKLY_SURVEY,
    surveyNames.BASELINE_SYMPTOM_SURVEY,
    surveyNames.BASELINE_QUALITY_OF_LIFE_SURVEY,
    surveyNames.BASELINE_DIAGNOSIS_AND_DEMOGRAPHICS_SURVEY,
  ]);

  const garminConnectedPromise = GarminService.isConnected();

  const enrollmentTasks = await Promise.all([
    taskPromise,
    baselineTaskPromise,
    garminConnectedPromise,
  ]).then(([tasks, baselineTasks, isConnected]) => {
    if (baselineTasks.length === 0) {
      tasks = tasks.filter(
        (t) => t.surveyName !== surveyNames.BASELINE_ASSESSMENT
      );
    }
    if (tasks.length > 0) {
      switch (tasks[0].surveyName) {
        case surveyNames.ELIGIBILITY:
          tasks[0].locked = state !== "Invited";
          break;
        case surveyNames.CONSENT:
          tasks[0].locked = state !== "Eligible";
          break;
        case surveyNames.BASELINE_ASSESSMENT:
          tasks[0].locked = state !== "Consented";
          break;
        case surveyNames.SETUP_GARMIN:
          tasks[0].locked = state !== "Consented" || baselineTasks.length > 0;
          break;
        case surveyNames.ORDER_GARMIN:
          tasks[0].locked =
            state !== "Consented" ||
            baselineTasks.length > 0 ||
            !isConnected ||
            !code;
          break;
        default:
          throw new Error("Unexpected survey name");
      }
    }

    return tasks;
  });

  return { enrollmentTasks, state };
}
