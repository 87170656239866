import "./study-resources.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BodyBattery from "../../components/wearable/body-battery";
import StepCount from "../../components/wearable/step-count";
import Stress from "../../components/wearable/stress";
import Wearable from "../../components/wearable";
import useCarousel from "../../hooks/use-carousel";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { LanguageContext } from "../../contexts/language-context";

const WearableEducation: React.FC = () => {
  const [props, _, setCurrentSlide] = useCarousel();
  const {wearable_education: lang, download} = useContext(LanguageContext);

  return (
    <div className="study-resources">
      <Carousel {...props}>
        <div>
          <div
            className="cover carousel-item-container"
            style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/assets/Wearable.svg')`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              height: "700px",
            }}
          >
            <h1 style={{ textAlign: "left" }}>
              {lang.title}
            </h1>
            <a
              className="btn-primary"
              href={download.link}
              target="_blank"
              rel="noreferrer"
            >
              {download.text}
            </a>
          </div>
        </div>
        <div>
          <div>
            <Wearable />
            <section>
              <div
                className="section-header"
                onClick={() => setCurrentSlide(2)}
              >
                <div>
                  <h5>{lang.sections[0].title}</h5>
                </div>
                <div>
                  <button className="btn-link">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            </section>
            <section>
              <div
                className="section-header"
                onClick={() => setCurrentSlide(3)}
              >
                <div>
                  <h5>{lang.sections[1].title}</h5>
                </div>
                <div>
                  <button className="btn-link">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            </section>
            <section>
              <div
                className="section-header"
                onClick={() => setCurrentSlide(4)}
              >
                <div>
                  <h5>{lang.sections[2].title}</h5>
                </div>
                <div>
                  <button className="btn-link">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div>
          <BodyBattery />
        </div>
        <div>
          <StepCount />
        </div>
        <div>
          <Stress />
        </div>
      </Carousel>
    </div>
  );
};

export default WearableEducation;
