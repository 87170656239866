import Loading from "../components/loading";
import MyDataHelps from "@careevolution/mydatahelps-js";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SurveyCard from "../components/survey-card";
import Task from "../types/Task";
import { LanguageContext } from "../contexts/language-context";
import parse from "html-react-parser";
import loader from "../loaders/baseline-assessment-tasks-loader";

const BaselineAssessment: React.FC<{
  onTaskComplete: (taskName: string) => void;
}> = ({ onTaskComplete }) => {
  const { baseline: lang } = useContext(LanguageContext);

  const [ loading, setLoading ] = useState<boolean>(true);
  const [ tasks, setTasks ] = useState<Task[]>([]);
  const [ complete, setComplete ] = useState<boolean>(false);

  const initialize = useCallback(async () => {
    setLoading(true);

    const baselineTasks = await loader();

    setTasks(baselineTasks);

    setComplete(baselineTasks.length === 0);

    setLoading(false);
  }, []); 

  const onSurveyFinish = useCallback(
    (result: { reason: string; surveyName: string }) => {
      if (result.reason === "Completed") {
        onTaskComplete(
          tasks.find((t) => t.surveyName === result.surveyName)
            ?.displayName || result.surveyName
        );

        initialize();
      }
    },
    [onTaskComplete, tasks, initialize]
  );

  useEffect(() => {
    initialize();
  }, [initialize])

  useEffect(() => {
    MyDataHelps.on("surveyDidFinish", onSurveyFinish);

    return () => {
      MyDataHelps.off("surveyDidFinish", onSurveyFinish);
    };
  }, [onSurveyFinish]);

  function renderComplete(complete: boolean) {
    if (!complete) {
      return <></>;
    }

    setTimeout(() => {
      MyDataHelps.back()
    }, 3000);

    return (
      <>
        <p>{lang.complete.title}</p>
        <p>{lang.complete.text}</p>
      </>
    );
  }

  return (
    <div>
      <div>
        <h1>{lang.title}</h1>
        <p>{parse(lang.text)}</p>
      </div>
      {
        loading && <Loading />
      }
      {
        !loading &&
            <div className="card-list">
              {tasks.map((t) => (
                <SurveyCard task={t} key={t.surveyName} />
              ))}
            </div>
      }
      {renderComplete(complete)}
    </div>
  );
};

export default BaselineAssessment;
