import React from "react";
import "./progress-bar.css";

const ProgressBar: React.FC<{ value: number }> = ({ value }) => {
  const len = 23.32; // Scale the length of each progress bar unit to 23.32% in order to make room for the watch icon and the gaps.
  const gap = 0.3;
  const intervals = [0, len + gap, 2 * len + 2 * gap, 3 * len + 3 * gap].map(
    (start) => ({ start, end: start + len, gradientEnd: start })
  );

  if (value >= 0 && value < 25) {
    intervals[0].gradientEnd = (value / 25) * len;
  } else if (value >= 25 && value < 50) {
    intervals[0].gradientEnd = intervals[0].end;
    intervals[1].gradientEnd = intervals[1].start + ((value - 25) / 25) * len;
  } else if (value >= 50 && value < 75) {
    intervals[0].gradientEnd = intervals[0].end;
    intervals[1].gradientEnd = intervals[1].end;
    intervals[2].gradientEnd = intervals[2].start + ((value - 50) / 25) * len;
  } else if (value >= 75 && value <= 100) {
    intervals[0].gradientEnd = intervals[0].end;
    intervals[1].gradientEnd = intervals[1].end;
    intervals[2].gradientEnd = intervals[2].end;
    intervals[3].gradientEnd = intervals[3].start + ((value - 75) / 25) * len;
  } else {
    throw new Error("Value must be between 0 and 100");
  }

  return (
    <div className="progress-bar">
      <div
        className="step step-first"
        style={{
          left: intervals[0].start + "%",
          right: 100 - intervals[0].end + "%",
        }}
      ></div>
      <div
        className="step step-gradient-1 step-first"
        style={{
          left: intervals[0].start + "%",
          right: 100 - intervals[0].gradientEnd + "%",
        }}
      ></div>
      <div
        className="step"
        style={{
          left: intervals[1].start + "%",
          right: 100 - intervals[1].end + "%",
        }}
      ></div>
      <div
        className="step step-gradient-2"
        style={{
          left: intervals[1].start + "%",
          right: 100 - intervals[1].gradientEnd + "%",
        }}
      ></div>
      <div
        className="step"
        style={{
          left: intervals[2].start + "%",
          right: 100 - intervals[2].end + "%",
        }}
      ></div>
      <div
        className="step step-gradient-3"
        style={{
          left: intervals[2].start + "%",
          right: 100 - intervals[2].gradientEnd + "%",
        }}
      ></div>
      <div
        className="step"
        style={{
          left: intervals[3].start + "%",
          right: 100 - intervals[3].end + "%",
        }}
      ></div>
      <div
        className="step step-gradient-4"
        style={{
          left: intervals[3].start + "%",
          right: 100 - intervals[3].gradientEnd + "%",
        }}
      ></div>
      <div className="watch-face">
        <img src={process.env.PUBLIC_URL + "/assets/watch.png"} alt="watch" />
      </div>
    </div>
  );
};

export default ProgressBar;
