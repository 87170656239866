import { NavigationBar } from "@careevolution/mydatahelps-ui";
import React from "react";

const Forbidden : React.FC = () => {
  return (
    <>
      <NavigationBar showBackButton={true} backButtonText=" Back" />
      <div>You are not allowed to access this page.</div>
    </>
  );
}

export default Forbidden;