import { useContext } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import parse from 'html-react-parser';

const ManagePots: React.FC = () => {
  const { managing_pots: lang } = useContext(LanguageContext);
  return (
    <div className="carousel-item-container">
      <h1>{lang.title}</h1>
      <p>
        <b>{parse(lang.sections[0].text[0])}</b>
        <br />
        {parse(lang.sections[0].text[1])}
      </p>
      <p>{parse(lang.sections[0].text[2])}</p>
      <div className="pots-grid">
        <div>{parse(lang.sections[0].text[3])}</div>
        <div>{parse(lang.sections[0].text[4])}</div>
        <div>{parse(lang.sections[0].text[5])}</div>
        <div>{parse(lang.sections[0].text[6])}</div>
      </div>
      <p>{parse(lang.sections[0].text[7])}</p>
      <p>
        <b>{parse(lang.sections[0].text[8])}</b>
        <br />
        {parse(lang.sections[0].text[9])}
      </p>
      <ol style={{ listStylePosition: 'outside' }}>
        <li>{parse(lang.sections[0].text[10])}</li>
        <li>{parse(lang.sections[0].text[11])}</li>
        <li>{parse(lang.sections[0].text[12])}</li>
        <li>{parse(lang.sections[0].text[13])}</li>
      </ol>
      <div style={{height:'50px'}}>
      </div>
    </div>
  );
};

export default ManagePots;
