import { useContext } from 'react';
import { LanguageContext } from '../../contexts/language-context';
import parse from 'html-react-parser';

const WhatIsLongCovid: React.FC = () => {
  const { what_is_long_covid: lang } = useContext(LanguageContext);
  return (
    <div className="carousel-item-container">
      <h1>{lang.title}</h1>
      <p>{parse(lang.sections[0].text)}</p>
      <hr />
      <h3 style={{ textAlign: 'center', color: 'black' }}>
        {lang.sections[1].text[0]}
      </h3>
      <p>{lang.sections[1].text[1]}</p>
      <ul>
        <li>{lang.sections[1].text[2]}</li>
        <li>{parse(lang.sections[1].text[3])}</li>
        <li>
          {lang.sections[1].text[4]}
          <ul className="symptoms">
            <li
              style={{
                listStyleImage: `url("${process.env.PUBLIC_URL}/assets/Fatigue.svg")`,
              }}
            >
              {lang.sections[1].text[5]}
            </li>
            <li
              style={{
                listStyleImage: `url("${process.env.PUBLIC_URL}/assets/Pem.svg")`,
              }}
            >
              {lang.sections[1].text[6]}
            </li>
            <li
              style={{
                listStyleImage: `url("${process.env.PUBLIC_URL}/assets/Sleep.svg")`,
              }}
            >
              {lang.sections[1].text[7]}
            </li>
            <li
              style={{
                listStyleImage: `url("${process.env.PUBLIC_URL}/assets/BrainFog.svg")`,
              }}
            >
              {lang.sections[1].text[8]}
            </li>
            <li
              style={{
                listStyleImage: `url("${process.env.PUBLIC_URL}/assets/Nss.svg")`,
              }}
            >
              {lang.sections[1].text[9]}
            </li>
            <li
              style={{
                listStyleImage: `url("${process.env.PUBLIC_URL}/assets/Headache.svg")`,
              }}
            >
              {lang.sections[1].text[10]}
            </li>
          </ul>
        </li>
      </ul>
      <p>{lang.sections[1].text[11]}</p>
      <p>{lang.sections[1].text[12]}</p>
      <div style={{height:'50px'}}>
      </div>
    </div>
  );
};

export default WhatIsLongCovid;
