import { useContext } from "react";
import { LanguageContext } from "../../contexts/language-context";
import parse from "html-react-parser";

const MoreInformation: React.FC = () => {
  const { more_info: lang} = useContext(LanguageContext);
  return (
    <div className="carousel-item-container">
      <h3 style={{ color: "black", textAlign: "center" }}>{lang.title}</h3>
      <p>
        {parse(lang.text[0])}
      </p>
      <p>
        {lang.text[1]}
        <a
          href={lang.link[0].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[0].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[2])}
        <a
          href={lang.link[1].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[1].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[3])}
        <a
          href={lang.link[2].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[2].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[4])}
        <a
          href={lang.link[3].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[3].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[5])}
        <a
          href={lang.link[4].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[4].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[6])}
        <a
          href={lang.link[5].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[5].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[7])}
        <a href={lang.link[6].url} target="_blank" rel="noreferrer">
          {lang.link[6].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[8])}
        <a
          href={lang.link[7].url} 
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[7].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[9])}
        <a
          href={lang.link[8].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[8].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[10])}
        <a
          href={lang.link[9].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[9].text}
        </a>
        .
      </p>
      <p>
        {parse(lang.text[11])}
        <a
          href={lang.link[10].url}
          target="_blank"
          rel="noreferrer"
        >
          {lang.link[10].text}
        </a>
        .
      </p>
      <div style={{height:'50px'}}>
      </div>
    </div>
  );
};

export default MoreInformation;
