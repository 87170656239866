import { NavigationBar } from "@careevolution/mydatahelps-ui";
import { useLocation } from "react-router-dom";

function NoMatch() {
  const location = useLocation();

  return (
    <div>
      <NavigationBar showBackButton={true} backButtonText=" Back" />
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default NoMatch;
