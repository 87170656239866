import "react-toastify/dist/ReactToastify.css";
import router from "./router";
import { RouterProvider } from "react-router-dom";
import { ToastContainer, toastTaskCompletion } from "./components/toastify";
import { Zoom } from "react-toastify";
import MyDataHelps from "@careevolution/mydatahelps-js";
import strings_en from "./i18n/strings-en.json";
import strings_es from "./i18n/strings-es.json";
import { LanguageContext } from "./contexts/language-context";

const App: React.FC = () => {
  const lang = MyDataHelps.getCurrentLanguage().toLowerCase();

  const isSpanish = () => lang.startsWith('es');

  const onTaskComplete = (taskName: string) => {
    const message = '<b><i>' + (isSpanish() ? 'Completo' : 'Completed') + '</i></b> ' + taskName;
    toastTaskCompletion(message);
  };

  return (
    <LanguageContext.Provider value={isSpanish() ? strings_es : strings_en}>
      <RouterProvider router={router(onTaskComplete)} />
      <ToastContainer
        position="bottom-center"
        autoClose={6000}
        hideProgressBar={true}
        theme="light"
        transition={Zoom}
      />
    </LanguageContext.Provider>
  );
};

export default App;
