import MyDataHelps from "@careevolution/mydatahelps-js";

function getGarminProviderID() {
	var garminProviderID = 6327;
	if (MyDataHelps.baseUrl && MyDataHelps.baseUrl.startsWith("https://mdhorg.ce.dev")) {
		garminProviderID = 1384;
	}
	return garminProviderID;
}

async function connectGarmin() {
  const providerID = getGarminProviderID();
  await MyDataHelps.connectExternalAccount(providerID, {
    openNewWindow: true,
  });
}

async function isConnected() {
  const providerID = getGarminProviderID();
  const accounts = await MyDataHelps.getExternalAccounts();
  return accounts.some((a) => a.provider.id === providerID);
}

const service = {
  connectGarmin,
  isConnected,
};

export default service;
