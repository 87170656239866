import { useContext } from "react";
import { LanguageContext } from "../../contexts/language-context";
import parse from "html-react-parser";

const StepCount: React.FC = () => {

  const {wearable_education: lang} = useContext(LanguageContext);
  return (
    <div className="carousel-item-container">
      <h4>{lang.sections[1].title}</h4>
      <p>
        {parse(lang.sections[1].text[0])}
      </p>
      <p>
        {parse(lang.sections[1].text[1])}
      </p>
    </div>
  );
};

export default StepCount;
