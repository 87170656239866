import MyDataHelps from "@careevolution/mydatahelps-js";
import Task from "../types/Task";
import surveyNames from "../config/survey-names.json"

async function getIncompleteSurveyTasks(surveyName: string[]): Promise<Task[]> {
  const page = await MyDataHelps.querySurveyTasks({
    surveyName,
    status: "incomplete",
  });

  const tasks = surveyName
    .map((n) => page.surveyTasks.find((t) => t.surveyName === n))
    .filter((t) => t !== undefined)
    .filter((t) => t!.dueDate == null || new Date(t!.dueDate) > new Date())
    .map((t) => ({
      surveyName: t!.surveyName,
      displayName: t!.surveyDisplayName || t!.surveyName,
      description: t!.surveyDescription || "",
      status: t!.status,
      completionTime: getSurveyCompletionTime(t!.surveyName).toString().padStart(2, '0'),
      hasSavedProgress: t!.hasSavedProgress,
      locked: true,
    }));

  return tasks;
}

function getSurveyCompletionTime(surveyName: string): number {
  if (surveyNames.BASELINE_ASSESSMENT === surveyName) return 7;
  if (surveyNames.BASELINE_DIAGNOSIS_AND_DEMOGRAPHICS_SURVEY === surveyName) return 2;
  if (surveyNames.BASELINE_QUALITY_OF_LIFE_SURVEY === surveyName) return 2;
  if (surveyNames.BASELINE_SYMPTOM_SURVEY === surveyName) return 2;
  if (surveyNames.BASELINE_WEEKLY_SURVEY === surveyName) return 2;
  if (surveyNames.CONSENT === surveyName) return 8;
  if (surveyNames.ELIGIBILITY === surveyName) return 1;
  if (surveyNames.ORDER_GARMIN === surveyName) return 8;
  if (surveyNames.QUARTERLY_QUALITY_OF_LIFE_SURVEY === surveyName) return 1;
  if (surveyNames.QUARTERLY_STUDY_RESOURCES_SURVEY === surveyName) return 1;
  if (surveyNames.QUARTERLY_SYMPTOM_SURVEY === surveyName) return 2;
  if (surveyNames.SETUP_GARMIN === surveyName) return 5;

  throw new Error("Unknown survey name");
}

const service = {
  getIncompleteSurveyTasks,
};

export default service;
