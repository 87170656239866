import BaselineAssessment from "./routes/baseline-assessment";
import ConnectGarmin from "./routes/connect-garmin";
import Enrollment from "./routes/enroll";
import ErrorPage from "./routes/error-page";
import Forbidden from "./routes/forbidden";
import LongCovid from "./routes/study-resources/long-covid";
import LongCovidSummary from "./routes/study-resources/long-covid-summary";
import NoMatch from "./routes/no-match";
import OrderGarmin from "./routes/order-garmin";
import QuarterlyAssessment from "./routes/quarterly-assessment";
import StudyResources from "./routes/study-resources";
import WearableEducation from "./routes/study-resources/wearable-education";
import { createBrowserRouter, Outlet } from "react-router-dom";
import { NavigationBar } from "@careevolution/mydatahelps-ui";

const Layout: React.FC = () => {
  return (
    <>
      <NavigationBar showBackButton={true}/>
      <Outlet />
    </>
  );
};

const SurveyLayout: React.FC = () => {
  return (
    <Outlet />
  );
};

const router = (onTaskComplete: (taskName: string) => void) =>
  createBrowserRouter([
    {
      id: "root",
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "enroll",
          element: <Enrollment onTaskComplete={onTaskComplete} />,
          errorElement: <ErrorPage />,
        },
        {
          path: "baseline",
          element: <BaselineAssessment onTaskComplete={onTaskComplete} />,
          errorElement: <ErrorPage />,
        },
        {
          path: "quarterly",
          element: <QuarterlyAssessment onTaskComplete={onTaskComplete} />,
          errorElement: <ErrorPage />,
        },
        {
          path: "resources",
          children: [
            {
              path: "",
              element: <StudyResources />,
            },
            {
              path: "long-covid-summary",
              element: <LongCovidSummary />,
            },
            {
              path: "long-covid",
              element: <LongCovid />,
            },
            {
              path: "wearable-education",
              element: <WearableEducation />,
            },
          ],
        },
        {
          path: "/forbidden",
          element: <Forbidden />,
        },
      ],
    },
    {
      id: "surveyRoot",
      path: "/garmin",
      element: <SurveyLayout />,
      children: [
        {
          path: "connect",
          element: <ConnectGarmin />,
          errorElement: <ErrorPage />,
        },
        {
          path: "order",
          element: <OrderGarmin />,
          errorElement: <ErrorPage />,
        }
      ]
    },
    {
      path: "*",
      element: <NoMatch />,
    },
  ]);

export default router;
